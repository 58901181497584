import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const Body = () => {
  const user = useSelector((state) => state.user.loggedInUser);
  const isLoading = useSelector((state) => state.appConfig.isLoading);
  const isAuth = user && user?.isAdmin;

  if (isAuth) {
    return (
      <div>
        <Header />
        {isLoading && <Spin fullscreen size="large" />}

        <div className="pt-28 container mx-auto bg-gray-50 p-5">
          <Outlet />
        </div>
        <Footer />
      </div>
    );
  } else {
    return <Navigate to={"/login"} />;
  }
};

export default Body;
