import React from "react";
import CatgoryList from "../components/CatgoryList";

const Category = () => {
  return (
    <div>
      <CatgoryList />
    </div>
  );
};

export default Category;
