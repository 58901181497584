import userReducer from "./userSlice";
import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./categorySlice";
import appConfigReducer from "./appConfigSlice";
import productReducer from "./productSlice";
const appStore = configureStore({
  reducer: {
    user: userReducer,
    category: categoryReducer,
    appConfig: appConfigReducer,
    product: productReducer,
  },
});
export default appStore;
