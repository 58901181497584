import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import httpService from "../utils/httpService";
import { LOGOUT } from "../constants/ApiUrls";
import { setLoggedInUser } from "../store/userSlice";
import { fetchCategories } from "../store/categorySlice";
import { fetchProducts } from "../store/productSlice";

const Header = () => {
  const user = useSelector((state) => state.user.loggedInUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchProducts());
  }, []);
  const items = [
    {
      label: (
        <button className="py-3" onClick={() => logout()}>
          Logout
        </button>
      ),
      key: "0",
    },
  ];

  const logout = async () => {
    const response = await httpService({
      method: "post",
      url: LOGOUT,
      data: {},
    });

    if (response.status === 200) {
      dispatch(setLoggedInUser(null));
      localStorage.removeItem("loggedInUser");
    }
  };
  return (
    <nav className="fixed top-0 left-0 z-20 w-full border-b border-gray-200 bg-white py-2.5 px-6 sm:px-4">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <NavLink className="flex items-center">
          <span className="self-center whitespace-nowrap text-xl font-bold">
            Matte Curve - Admin
          </span>
        </NavLink>

        <div
          className="hidden w-full items-center justify-between md:order-1 md:flex md:w-auto"
          id="navbar-sticky"
        >
          <ul className="mt-4 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 md:mt-0 md:flex-row md:space-x-8 md:border-0 md:bg-white md:text-sm md:font-medium">
            <li>
              <NavLink
                to="/"
                className="block rounded bg-blue-700 py-2 pl-3 pr-4 text-gray-700 md:bg-transparent md:p-0 md:hover:text-blue-700"
                aria-current="page"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/category"
                className="block rounded bg-blue-700 py-2 pl-3 pr-4 text-gray-700 md:bg-transparent md:p-0 md:hover:text-blue-700"
                aria-current="page"
              >
                Category
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products"
                className="block rounded py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-100 md:p-0 md:hover:bg-transparent md:hover:text-blue-700"
              >
                Products
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/orders"
                className="block rounded py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-100 md:p-0 md:hover:bg-transparent md:hover:text-blue-700"
              >
                Orders
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/reports"
                className="block rounded py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-100 md:p-0 md:hover:bg-transparent md:hover:text-blue-700"
              >
                Reports
              </NavLink>
            </li>
          </ul>
        </div>
        {user && (
          <div className="md:order-2">
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <div className="cursor-pointer">
                <Avatar
                  style={{ backgroundColor: "#87d068" }}
                  icon={<UserOutlined />}
                />
                <span className="mx-2">{user?.username}</span>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
