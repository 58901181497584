import { Button, Input, Select, Spin, Switch, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import UploadImage from "./UploadImage";
import { PlusOutlined } from "@ant-design/icons";
import { ADD_PRODUCT, API_URL, UPLOAD_BLOB } from "../constants/ApiUrls";
import httpService from "../utils/httpService";
import { addProduct, updateProduct } from "../store/productSlice";
import { setLoader } from "../store/appConfigSlice";
const { TextArea } = Input;
const ProductForm = ({ handleOk, product }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState([]);
  const categories = useSelector((state) => state.category.categories);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.appConfig.isLoading);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (product) {
      reset({ ...product });
      setFileList(
        product.images.map((url) => {
          return { url };
        })
      );
    }
  }, [product]);

  const onSubmit = async (data) => {
    data["images"] = fileList.map((file) => file.url);
    console.log(data);
    try {
      const response = await httpService({
        method: product ? "put" : "post",
        url: product ? ADD_PRODUCT + `/${product._id}` : ADD_PRODUCT,
        data,
      });
      if (response && response?.data) {
        if (!product) {
          dispatch(addProduct(response.data));
        } else {
          dispatch(updateProduct(response.data));
        }
        messageApi.open({
          type: "success",
          content: `Product added successfully`,
          duration: 5,
        });
        reset({});
        setFileList([]);
        handleOk();
      }
    } catch (e) {
      messageApi.open({
        type: "error",
        content: `something went wrong`,
        duration: 5,
      });
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const onDelete = async (file) => {
    dispatch(setLoader(true));
    try {
      const response = await fetch(
        `${API_URL}${UPLOAD_BLOB}?filename=${file.url}`,
        {
          method: "DELETE",
        }
      );
      const json = await response.json();
      console.log(json);
      dispatch(setLoader(false));
    } catch (e) {
      dispatch(setLoader(false));

      console.log(e);
    }
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      dispatch(setLoader(true));
      const response = await fetch(
        `${API_URL}${UPLOAD_BLOB}?filename=products/${file.name}`,
        {
          method: "POST",
          body: file,
          onUploadProgress: (event) => {
            console.log((event.loaded / event.total) * 100);
            onProgress({ percent: (event.loaded / event.total) * 100 }, file);
          },
        }
      );
      const json = await response.json();
      file.url = json?.data?.imageUrl;
      onSuccess("Ok");
      dispatch(setLoader(false));
    } catch (err) {
      console.log(err);
      dispatch(setLoader(false));

      onError({ err });
    }
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  return (
    <div>
      {contextHolder}
      {isLoading && <Spin fullscreen size="large" />}

      <h1 className="font-bold text-xl my-3 mb-4 border-b-2 pb-3">
        Add Product
      </h1>
      <p>All fields are required.</p>
      <form
        className="flex flex-col text-lg gap-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex gap-4">
          <div className="w-[50%] flex gap-3 flex-col">
            <label className="flex flex-col gap-2">
              Product Name:
              <Controller
                control={control}
                name="name"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Input
                    onChange={onChange}
                    value={value}
                    placeholder="Product name"
                  />
                )}
              />
              <span>
                {errors.name && (
                  <p className="text-xs text-red-500">Name is required field</p>
                )}
              </span>
            </label>

            <label className="flex flex-col gap-2">
              Description:
              <Controller
                control={control}
                name="description"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextArea
                    onChange={onChange}
                    value={value}
                    rows={4}
                    placeholder="Enter product description"
                  />
                )}
              />
              <span>
                {errors.description && (
                  <p className="text-xs text-red-500">
                    Description is required field
                  </p>
                )}
              </span>
            </label>

            <label className="flex flex-col gap-2">
              Category:
              <Controller
                control={control}
                name="category"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Select Category"
                    options={categories}
                    fieldNames={{ label: "name", value: "_id" }}
                  />
                )}
              />
              <span>
                {errors.category && (
                  <p className="text-xs text-red-500">
                    Category is required field
                  </p>
                )}
              </span>
            </label>

            <label className="flex flex-col gap-2">
              In Stock Count:
              <Controller
                control={control}
                name="countInStock"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Input
                    type="number"
                    onChange={onChange}
                    value={value}
                    placeholder="Stock count"
                  />
                )}
              />
              <span>
                {errors.countInStock && (
                  <p className="text-xs text-red-500">
                    In Stock is required field
                  </p>
                )}
              </span>
            </label>

            <label className="flex gap-3 items-center">
              Active:
              <Controller
                control={control}
                defaultValue={true}
                name="active"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Switch
                    defaultChecked
                    value={value}
                    onChange={onChange}
                    checked={value}
                  />
                )}
              />
            </label>
          </div>

          <div className="w-[50%] flex gap-3 flex-col">
            <label className="flex flex-col gap-2">
              Brand:
              <Controller
                control={control}
                name="brand"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Input
                    onChange={onChange}
                    value={value}
                    placeholder="Brand name"
                  />
                )}
              />
              <span>
                {errors.brand && (
                  <p className="text-xs text-red-500">
                    Brand is required field
                  </p>
                )}
              </span>
            </label>

            <label className="flex flex-col gap-2">
              MRP:
              <Controller
                control={control}
                name="mrp"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Input
                    type="number"
                    onChange={onChange}
                    value={value}
                    placeholder="Enter product mrp"
                  />
                )}
              />
              <span>
                {errors.mrp && (
                  <p className="text-xs text-red-500">MRP is required field</p>
                )}
              </span>
            </label>

            <label className="flex flex-col gap-2">
              Price:
              <Controller
                control={control}
                rules={{ required: true }}
                name="price"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Input
                    type="number"
                    onChange={onChange}
                    value={value}
                    placeholder="Enter product selling price"
                  />
                )}
              />
              <span>
                {errors.price && (
                  <p className="text-xs text-red-500">
                    Price is required field
                  </p>
                )}
              </span>
            </label>
            <div className="flex flex-col gap-2">
              Image:
              <Upload
                listType="picture-card"
                accept="image/*"
                fileList={fileList}
                customRequest={uploadImage}
                onRemove={onDelete}
                onChange={handleChange}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
            </div>
          </div>
        </div>

        <div className="flex gap-3 justify-end">
          <Button
            htmlType="button"
            onClick={(e) => {
              handleOk();
              reset({});
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={fileList.length === 0}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ProductForm;
