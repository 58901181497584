import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpService from "../utils/httpService";
import { GET_CATEGORIES } from "../constants/ApiUrls";

export const fetchCategories = createAsyncThunk(
  "mattecurve/categories",
  async () => {
    try {
      const response = await httpService({
        method: "GET",
        url: GET_CATEGORIES,
      });
      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      return [];
    }
    return [];
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    editCategory: null,
    categories: [],
  },
  reducers: {
    setEditCategory: (state, action) => {
      state.editCategory = action.payload;
    },
    addCategory: (state, action) => {
      state.categories.push(action.payload);
    },
    updateCategory: (state, action) => {
      const index = state.categories.findIndex(
        (c) => c._id === action.payload._id
      );
      state.categories[index] = action.payload;
    },
    remoreCategory: (state, action) => {
      let id = action.payload.id;
      state.categories = state.categories.filter((item) => item._id !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
  },
});

export const { setEditCategory, addCategory, remoreCategory, updateCategory } =
  categorySlice.actions;
export default categorySlice.reducer;
