const MainContainer = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-[50%] p-2 flex flex-col gap-5">
        <div className="bg-white h-96 p-8 rounded-md w-full"> Charts </div>
        <div className="bg-white h-96 p-8 rounded-md w-full">Notifications</div>
      </div>
      <div className="w-[50%] p-2 flex flex-col gap-5">
        <div className="bg-white h-96 p-8 rounded-md w-full"> Orders </div>
        <div className="bg-white h-96 p-8 rounded-md w-full"> Reports </div>
      </div>
    </div>
  );
};

export default MainContainer;
