import { Button, Input, Switch, message } from "antd";
import TextArea from "antd/es/input/TextArea";

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import UploadImage from "./UploadImage";
import httpService from "../utils/httpService";
import { CREATE_CATEGORY } from "../constants/ApiUrls";
import { useDispatch } from "react-redux";
import { addCategory, updateCategory } from "../store/categorySlice";

const CategoryForm = ({ handleOk, category }) => {
  const [image, setImage] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (category) {
      reset({ ...category });
      setImage(category?.image);
    }
  }, [category]);

  const onSubmit = async (data) => {
    data["image"] = image;

    try {
      const response = await httpService({
        method: category ? "PUT" : "POST",
        url: category ? CREATE_CATEGORY + `/${category._id}` : CREATE_CATEGORY,
        data: data,
      });

      if (response && response?.data) {
        if (category) {
          dispatch(updateCategory(response.data));
        } else {
          dispatch(addCategory(response.data));
        }
        messageApi.open({
          type: "success",
          content: `Category ${category ? "updated" : "added"} successfully`,
          duration: 5,
        });
        reset({});
      }
      setImage(null);
      handleOk();
    } catch (e) {
      messageApi.open({
        type: "error",
        content: `something went wrong`,
        duration: 5,
      });
    }
  };
  return (
    <div>
      {contextHolder}
      <h1 className="font-bold text-xl my-3 mb-4 border-b-2 pb-3">
        Add Category
      </h1>
      <form
        className="flex flex-col text-lg gap-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <label className="flex flex-col gap-2">
          Name:
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                onChange={onChange}
                value={value}
                placeholder="Category name"
              />
            )}
          />
        </label>

        <label className="flex flex-col gap-2">
          Description:
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextArea
                onChange={onChange}
                value={value}
                rows={4}
                placeholder="Enter category description"
              />
            )}
          />
        </label>

        <div className="flex flex-col gap-2">
          Image:
          <UploadImage setImage={setImage} image={image} />
        </div>

        <label className="flex gap-3 items-center">
          Active:
          <Controller
            control={control}
            defaultValue={true}
            name="active"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Switch
                defaultChecked
                value={value}
                onChange={onChange}
                checked={value}
              />
            )}
          />
        </label>

        <div className="flex gap-3 justify-end">
          <Button
            htmlType="button"
            onClick={(e) => {
              handleOk();
              setImage(null);
            }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CategoryForm;
