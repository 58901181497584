import { Button, Input, Modal, Popconfirm, message } from "antd";
import React, { useState } from "react";
import ProductForm from "./ProductForm";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, updateProduct } from "../store/productSlice";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import httpService from "../utils/httpService";
import { ADD_PRODUCT, PORTAL_URL } from "../constants/ApiUrls";
import { Link } from "react-router-dom";

const ProductList = () => {
  const [show, setShow] = useState(false);
  const [product, setProduct] = useState(null);
  const products = useSelector((state) => state.product.products);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [count, setCount] = useState(null);
  const [mrp, setMrp] = useState(null);
  const [price, setPrice] = useState(null);
  const [showCountInput, setShowCountInput] = useState(null);
  const [showPriceInput, setShowPriceInput] = useState(null);
  const [showMrpInput, setShowMrpInput] = useState(null);

  const showModal = (product) => {
    setProduct(product);
    setShow(true);
  };
  const handleOk = () => {
    setProduct(null);
    setShow(false);
  };
  const handleCancel = () => {
    setShow(false);
  };

  const confirm = async (product) => {
    try {
      const response = await httpService({
        method: "DELETE",
        url: ADD_PRODUCT + `/${product._id}`,
      });
      if (response && response.data) {
        dispatch(deleteProduct({ id: response.data._id }));
        messageApi.open({
          type: "success",
          content: "Product deleted successfully",
          duration: 5,
        });
      }
    } catch (e) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
        duration: 5,
      });
    }
  };
  const cancel = (e) => {};

  const onSave = async (product) => {
    try {
      const response = await httpService({
        method: "put",
        url: ADD_PRODUCT + `/${product._id}`,
        data: {
          ...product,
          price: showPriceInput ? +price : product.price,
          mrp: showMrpInput ? +mrp : product.mrp,
          countInStock: showCountInput ? +count : product.countInStock,
        },
      });
      if (response && response?.data) {
        dispatch(updateProduct(response.data));
        setShowCountInput(null);
        setShowMrpInput(null);
        setShowPriceInput(null);
        setCount(null);
        setPrice(null);
        setMrp(null);
        messageApi.open({
          type: "success",
          content: `Product update successfully`,
          duration: 5,
        });
      }
    } catch (e) {
      messageApi.open({
        type: "error",
        content: `something went wrong`,
        duration: 5,
      });
    }
  };

  return (
    <div className="bg-white p-8 rounded-md w-full">
      {contextHolder}
      <div className=" flex items-center justify-between pb-6">
        <div>
          <h2 className="text-gray-600 font-semibold text-xl">Products</h2>
          <span className="text-md">All products</span>
        </div>
        <div className="flex items-center justify-between">
          <div className="lg:ml-40 ml-10 space-x-8">
            <Button
              type="primary"
              onClick={() => {
                showModal(null);
              }}
            >
              Add Product
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-24">
                    Image
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-64">
                    Name
                  </th>

                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Brand
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Stock
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Price
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    MRP
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {products?.map((product) => {
                  return (
                    <tr>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <img
                          className="w-50 h-50 aspect-auto"
                          src={product.images[0]}
                          alt={product.name}
                        />
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              <Link
                                target="_blank"
                                to={PORTAL_URL + "/product/" + product._id}
                                className="hover:text-blue-800"
                                href="#"
                              >
                                {product.name}
                              </Link>
                            </p>
                          </div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {product.brand}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {showCountInput === product._id ? (
                          <div>
                            <Input
                              type="number"
                              value={count}
                              onChange={(e) => setCount(e.target.value)}
                            />
                            <div className="flex gap-3 text-lg mt-2 justify-end">
                              <CloseOutlined
                                className="cursor-pointer"
                                onClick={() => {
                                  setShowCountInput(null);
                                  setCount(null);
                                }}
                              />

                              <CheckCircleOutlined
                                className="cursor-pointer"
                                onClick={() => onSave(product)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex gap-2">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {product.countInStock}
                            </p>
                            <EditTwoTone
                              onClick={(e) => {
                                setCount(product.countInStock);
                                setShowCountInput(product._id);
                              }}
                              className="mr-2 cursor-pointer text-xl"
                            />
                          </div>
                        )}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {showPriceInput === product._id ? (
                          <div>
                            <Input
                              type="number"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                            <div className="flex gap-3 text-lg mt-2 justify-end">
                              <CloseOutlined
                                className="cursor-pointer"
                                onClick={() => {
                                  setShowPriceInput(null);
                                  setPrice(null);
                                }}
                              />

                              <CheckCircleOutlined
                                onClick={() => onSave(product)}
                                className="cursor-pointer"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex gap-2">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {product.price}
                            </p>
                            <EditTwoTone
                              onClick={(e) => {
                                setPrice(product.price);
                                setShowPriceInput(product._id);
                              }}
                              className="mr-2 cursor-pointer text-xl"
                            />
                          </div>
                        )}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {showMrpInput === product._id ? (
                          <div>
                            <Input
                              type="number"
                              value={mrp}
                              onChange={(e) => setMrp(e.target.value)}
                            />
                            <div className="flex gap-3 text-lg mt-2 justify-end">
                              <CloseOutlined
                                className="cursor-pointer"
                                onClick={() => {
                                  setShowMrpInput(null);
                                  setMrp(null);
                                }}
                              />

                              <CheckCircleOutlined
                                onClick={() => onSave(product)}
                                className="cursor-pointer"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex gap-2">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {product.mrp}
                            </p>
                            <EditTwoTone
                              onClick={(e) => {
                                setMrp(product.mrp);
                                setShowMrpInput(product._id);
                              }}
                              className="mr-2 cursor-pointer text-xl"
                            />
                          </div>
                        )}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                          <span
                            aria-hidden
                            className={`absolute inset-0 ${
                              product.active ? "bg-green-200" : "bg-red-200"
                            } opacity-50 rounded-full`}
                          />
                          <span className="relative">
                            {product.active ? "Active" : "Inactive"}
                          </span>
                        </span>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white ">
                        <EditOutlined
                          onClick={(e) => showModal(product)}
                          className="mr-2 cursor-pointer text-xl"
                        />
                        <Popconfirm
                          title="Delete the Category"
                          description="Are you sure to delete this category?"
                          onConfirm={() => confirm(product)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined className="cursor-pointer text-xl" />
                        </Popconfirm>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal closable={false} open={show} footer={null} width={1000}>
        <ProductForm product={product} handleOk={handleOk} />
      </Modal>
    </div>
  );
};

export default ProductList;
