import React, { useEffect, useState } from "react";
import { Button, Modal, Popconfirm, message } from "antd";
import CategoryForm from "./CategoryForm";
import httpService from "../utils/httpService";
import { CREATE_CATEGORY, GET_CATEGORIES } from "../constants/ApiUrls";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  remoreCategory,
  setEditCategory,
} from "../store/categorySlice";

const CatgoryList = () => {
  const categories = useSelector((state) => state.category.categories);
  const [messageApi, contextHolder] = message.useMessage();
  const [category, setCategory] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const showModal = (category) => {
    if (category) {
      setCategory(category);
    }
    setOpen(true);
  };
  const handleOk = () => {
    setCategory(null);
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const confirm = async (cat) => {
    try {
      const response = await httpService({
        method: "DELETE",
        url: CREATE_CATEGORY + `/${cat._id}`,
      });
      if (response && response.data) {
        dispatch(remoreCategory({ id: response.data._id }));
        messageApi.open({
          type: "success",
          content: "Category deleted successfully",
          duration: 5,
        });
      }
    } catch (e) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
        duration: 5,
      });
    }
  };
  const cancel = (e) => {};

  return (
    <div>
      {contextHolder}
      <div className="bg-white p-8 rounded-md w-full">
        <div className=" flex items-center justify-between pb-6">
          <div>
            <h2 className="text-gray-600 font-bold text-xl">Add Categories</h2>
            <span className="text-md">
              Categories means list of link which will be shown on header
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div className="lg:ml-40 ml-10 space-x-8 text-lg">
              <Button
                type="primary"
                onClick={() => {
                  showModal();
                }}
              >
                Add Category
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left font-semibold text-gray-600 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left font-semibold text-gray-600 uppercase tracking-wider">
                      Description
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left font-semibold text-gray-600 uppercase tracking-wider">
                      Image
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left font-semibold text-gray-600 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left font-semibold text-gray-600 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((cat) => {
                    return (
                      <tr key={cat._id}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white ">
                          <div className="flex items-center">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {cat.name}
                            </p>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white ">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {cat.description}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white ">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {cat.image ? (
                              <img
                                className="w-20 h-20 aspect-auto"
                                src={cat.image}
                                alt={cat.name}
                              />
                            ) : (
                              "Not Available"
                            )}
                          </p>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white ">
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              aria-hidden
                              className={`absolute inset-0 ${
                                cat.active ? "bg-green-200" : "bg-red-200"
                              } opacity-50 rounded-full`}
                            />
                            <span className="relative">
                              {cat.active ? "Active" : "Inactive"}
                            </span>
                          </span>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white ">
                          <EditOutlined
                            onClick={(e) => showModal(cat)}
                            className="mr-2 cursor-pointer text-xl"
                          />
                          <Popconfirm
                            title="Delete the Category"
                            description="Are you sure to delete this category?"
                            onConfirm={() => confirm(cat)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined className="cursor-pointer text-xl" />
                          </Popconfirm>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal closable={false} open={open} footer={null}>
        <CategoryForm category={category} handleOk={handleOk} />
      </Modal>
    </div>
  );
};

export default CatgoryList;
