import { createSlice } from "@reduxjs/toolkit";

const userInLocalStorage = localStorage.getItem("loggedInUser");
let loggedInUser = userInLocalStorage ? JSON.parse(userInLocalStorage) : null;

const userSlice = createSlice({
  name: "user",
  initialState: {
    loggedInUser,
  },
  reducers: {
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
  },
});

export const { setLoggedInUser } = userSlice.actions;

export default userSlice.reducer;
