export const API_URL = process.env.REACT_APP_API_URL;
export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;
export const AUTH_URL = "/api/users/auth";
export const LOGOUT = "/api/users/logout";
export const UPLOAD_BLOB = "/api/upload/blob";
export const CREATE_CATEGORY = "/api/category";
export const GET_CATEGORIES = "api/category/categories";
export const CREATE_USER = "/api/users";
export const ADD_PRODUCT = "/api/products";
export const GET_ALL_PRODUCTS = "/api/products/allproducts";
