import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { API_URL, UPLOAD_BLOB } from "../constants/ApiUrls";

const UploadImage = ({ setImage, image }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [fileList, setFileList] = useState([]);
  const inputFileRef = useRef(null);
  const handleChange = ({ fileList: newFileList }) => {
    if (imageUrl) {
      onDelete();
    }
    setFileList(newFileList);
  };

  useEffect(() => {
    if (image) {
      setFileList([{ url: image }]);
    } else {
      setFileList([]);
    }
  }, [image]);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const onDelete = async () => {
    const response = await fetch(
      `${API_URL}${UPLOAD_BLOB}?filename=${imageUrl}`,
      {
        method: "DELETE",
      }
    );
    const json = await response.json();
    setImageUrl(null);
    setImage(null);
    console.log(json);
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      const response = await fetch(
        `${API_URL}${UPLOAD_BLOB}?filename=categories/${file.name}`,
        {
          method: "POST",
          body: file,
          onUploadProgress: (event) => {
            console.log((event.loaded / event.total) * 100);
            onProgress({ percent: (event.loaded / event.total) * 100 }, file);
          },
        }
      );
      const json = await response.json();
      onSuccess("Ok");
      setImageUrl(json?.data?.imageUrl);
      setImage(json?.data?.imageUrl);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Upload
        listType="picture-circle"
        accept="image/*"
        fileList={fileList}
        customRequest={uploadImage}
        onRemove={onDelete}
        onChange={handleChange}
        maxCount={1}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
    </>
  );
};
export default UploadImage;
