import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpService from "../utils/httpService";
import { GET_ALL_PRODUCTS } from "../constants/ApiUrls";

export const fetchProducts = createAsyncThunk(
  "mattecurve/products",
  async () => {
    try {
      const response = await httpService({
        method: "GET",
        url: GET_ALL_PRODUCTS,
      });
      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      return [];
    }
    return [];
  }
);
const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    editProduct: null,
  },
  reducers: {
    addProduct: (state, action) => {
      state.products.push(action.payload);
    },
    deleteProduct: (state, action) => {
      const index = state.products.findIndex(
        (item) => item._id === action.payload.id
      );
      if (index !== -1) {
        state.products.splice(index, 1);
      }
    },
    setEditProduct: (state, action) => {
      state.editProduct = action.payload;
    },
    updateProduct: (state, action) => {
      let index = state.products.findIndex(
        (item) => item._id === action.payload._id
      );
      state.products[index] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload;
    });
  },
});

export const { deleteProduct, setEditProduct, updateProduct, addProduct } =
  productSlice.actions;
export default productSlice.reducer;
